icons {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 130px;
    margin: 20px;

    icon {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
    }
  }
}
